// @flow

import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core";

type StaggeredLayoutContent = {heading: string, imagePath: string, text: string};
type Props = {content: Array<StaggeredLayoutContent>};

export default function CMStaggeredLayout({ content }: Props): React.Node {
  const theme = useTheme();
  const medScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        bgcolor={theme.palette.primary.main}
        color={theme.palette.primary.contrastText}
        mb={isMobile ? "40px" : "56px"}
        py={isMobile ? "36px" : "56px"}>
        <Box
          bgcolor={theme.palette.primary.main}
          mb="16px"
          textAlign="center">
          <Typography component="h1" variant={isMobile ? "h4" : "h3"}>
            How it works
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography component="p" variant="body1">
            Get your FREE analysis in one easy step. Our analysis will cover:
          </Typography>
        </Box>
      </Box>
      <Container>
        <Box
          maxWidth={largeScreen ? "924px" : "100%"}
          mx="auto"
          width="100%">
          {content.map(({heading, imagePath, text}, idx) => (
            <Box
              key={idx}
              mb="32px">
              <Grid
                container
                direction={medScreen ? idx % 2 == 0 ? "row-reverse" : "row" : "column-reverse"}
                justify="center"
                key={idx}
                spacing={3}>
                <Grid
                  item
                  sm={6}
                  xs={12}>
                  <Box
                    alignItems={medScreen ? "flex-start" : "center"}
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center">
                    <Box mb="16px">
                      <Typography
                        align={medScreen ? "left" : "center"}
                        component="h1"
                        variant="h5">
                        {heading}
                      </Typography>
                    </Box>
                    <Typography align={medScreen ? "left" : "center"}>
                      {text}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}>
                  <Box
                    align="center"
                    mb="16px"
                    mx="auto"
                    width={medScreen ? "100%" : "156px"}>
                    <CardMedia
                      component="img"
                      image={imagePath} />
                  </Box>
                </Grid>
              </Grid> 
            </Box>
          ))}
        </Box>
      </Container>
    </>
  );
}
