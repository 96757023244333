// @flow

import * as React from "react";
import {
  Button,
  CardMedia,
  Container,
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    padding: "150px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
  },
  contentGridItem: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  heading: {
    color: "#605E5C",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: '34px',
    },
  },
  imageGridItem: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageItemMobile: {
    paddingBottom: "16px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  linkButton: {
    backgroundColor: "#6200EE",
    color: "#FFFFFF",
    minWidth: '211px',
  },
  subText: {
    color: "#605E5C",
    marginBottom: "48px",
  },
}));

const CMHero = (): React.Node => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container className={classes.root} maxWidth="lg">
      <Grid
        alignItems="center"
        container
        direction="row"
        justify="space-between"
        spacing={3}
      >
        <Grid
          className={classes.contentGridItem}
          item
          xs={12}
          md={7}
        >
          <Typography className={classes.heading} variant='h3'>
            Your real-time user experience advisor.
          </Typography>
          <CardMedia
            className={classes.imageItemMobile}
            component="img"
            image="https://images.ctfassets.net/r1jq271p6fy4/6szffQy6LcSO4skmBJ5vIy/c76f4e61aa84cc241ef6ab8874e542d1/Contact_01a_large.jpg"
          />
          <Typography className={classes.subText}>
            ColorMotive is a free tool to analyze your website colors and compare them to expected perception of your brand.
          </Typography>
          <Button
            className={classes.linkButton}
            fullWidth={isMobile}
            type="submit"
            variant="outlined"
          >
            Get Started
          </Button>
        </Grid>
        <Grid
          className={classes.imageGridItem}
          item
          md={4}
          xs={0}
        >
          <CardMedia
            component="img"
            image="https://images.ctfassets.net/r1jq271p6fy4/6szffQy6LcSO4skmBJ5vIy/c76f4e61aa84cc241ef6ab8874e542d1/Contact_01a_large.jpg"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CMHero;
