// @flow

import * as React from "react";
import { Router } from "@reach/router"
import Home from "../pages/home";
import Results from "../pages/results";

const Index = (): React.Node => (
  <Router>
    <Home path="/" /> 
    <Results path="/results" />
  </Router>
);

export default Index;
