// @flow

import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { CMContext } from "../../provider";
import { ACTION } from "../../reducer";
import { isValidUrl } from "../utils/formUtils";
import {
  useColorMotiveAPI,
  getCMApiCallJobs,
  getCMApiCallJobData,
  CMApiCallJobData,
} from "../utils/colormotive_api_calls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import CMHeader from "../components/header";
import CMHero from "../components/hero";
import CMLogo from "../components/logo";
import CMStaggeredLayout from "../components/staggered-layout";
import LocalStorage from "../LocalStorage";
import GAEventLogger, {
  GAEventActions,
  GAEventCategories,
} from "../utils/google_analytics/GAEventLogger";
import QueryString from "../utils/QueryString";
import theme from '../theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Head from '../components/head';

const Home = (): React.Node => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { dispatch } = useContext(CMContext);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [url, setURL] = useState<string>("");

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '110px',
      [theme.breakpoints.up('md')]: {
        marginTop: '158px',
      },
    },
    gridContainer: {
      borderBottom: '1px solid rgba(33, 33, 33, 0.08)',
      marginBottom: '40px',
      paddingBottom: '57px',
      [theme.breakpoints.up('md')]: {
        borderBottom: 'none',
        marginBottom: '0',
        paddingBottom: '81px',
      },
    },
    logoImage: {
      marginTop: '44px',
      maxWidth: '160px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        marginTop: '34px',
        maxWidth: '172px',
      },
    },
    analyzeInput: {
      '& input': {
        height: '33px',
      }
    },
    startButton: {
      height: '56px',
    },
    topGridText: {
      order: '2',
      [theme.breakpoints.up('md')]: {
        order: '1',
      },
    },
    topGridImage: {
      order: '1',
      [theme.breakpoints.up('md')]: {
        order: '2',
      },
    },
  }));
  const {
    analyzeInput,
    gridContainer,
    logoImage,
    root,
    startButton,
    topGridImage,
    topGridText
  } = useStyles();

  const setJobInfoAndRedirect = useCallback(
    (job_id: string, website_url: string): void => {
      dispatch({
        type: ACTION.SET_JOB_ID,
        payload: {
          cm_api_info: {
            job_id,
          },
          form_info: {
            adjectives_selected: [],
            adjectives_shown: [],
            website_url,
          },
          siteAnalysisStep: "scanning",
        },
      });
      LocalStorage.set("cm-job-id", job_id);
      navigate("/results");
    },
    [dispatch],
  );

  const onComplete = useCallback(
    (response) => {
      setJobInfoAndRedirect(response?.data?.job_id ?? "", url);
    },
    [setJobInfoAndRedirect, url],
  );

  const onError = useCallback(
    (_error) => {
      setHasValidationError(true);
    }
  );

  const { error, executeApiCall: submitURL, isLoading } = useColorMotiveAPI(
    () => getCMApiCallJobs({ url }),
    // run immediately
    false,
    // run on initial load
    false,
    onComplete,
    onError,
  );

  const handleSubmit = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (!isValidUrl(url)) {
        setHasValidationError(true);
        return;
      }
      // Log click event in Google Analytics
      GAEventLogger.logEvent(
        GAEventCategories.URL_ENTRY,
        GAEventActions.BUTTON_CLICK,
        {
          button: "START_BUTTON",
        },
      );
      const data = await submitURL();
    },
    [submitURL, url],
  );

  const onBlur = useCallback(
    (event: SyntheticEvent<HTMLInputElement>): void => {
      !isValidUrl(url) && setHasValidationError(true);
      // Log that user entered value in text input in Google Analytics
      GAEventLogger.logEvent(
        GAEventCategories.URL_ENTRY,
        GAEventActions.USER_INPUT,
        {
          url: url,
        },
      );
    },
    [url],
  );

  const onChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>): void => {
      hasValidationError && setHasValidationError(false);
      setURL(event.currentTarget.value.trim());
    },
    [hasValidationError, setURL],
  );

  useEffect(() => {
    if (hasValidationError || error) {
      // Log that user was shown an error message in Google Analytics
      GAEventLogger.logEvent(
        GAEventCategories.URL_ENTRY,
        GAEventActions.DISPLAY,
        {
          error: error?.response?.data.msg ?? "the url entered was malformed.",
        },
      );
    }
  }, [error, hasValidationError]);

  const fetchUrlAndSetJobInfo = useCallback(
    async (job_id: string) => {
      try {
        const {
          data: { url },
        } = await getCMApiCallJobData(job_id, CMApiCallJobData.URL);
        setJobInfoAndRedirect(job_id, url);
      } catch (err) {
        console.warn("There was an error fetching the url from api.");
        console.error(err);
      }
    },
    [setJobInfoAndRedirect],
  );

  const staggeredGridContent = [
    // For each row
    {
      "heading": "Colors and Emotions",
      "imagePath": require(`../assets/images/Color_Analysis.png`),
      "text": "Find out which emotions your current colors convey based on color psychology research.",
    },
    {
      "heading": "Emotion Comparison",
      "imagePath": require(`../assets/images/Color_Pass.png`),
      "text": "See a breakdown of how strongly each emotion is being portrayed.",
    },
    {
      "heading": "Additional Features",
      "imagePath": require(`../assets/images/Color_Breakdown.png`),
      "text": "See if your colors meet accessibility standards and recommended color ratios.",
    },
  ];

  const heroImage = require(`../assets/images/HomepageHero.png`);

  useEffect(() => {
    const queryParamJobID = QueryString.get("job_id");
    if (queryParamJobID != null) {
      fetchUrlAndSetJobInfo(queryParamJobID);
    }
  }, [fetchUrlAndSetJobInfo]);

  return (
    <ThemeProvider theme={theme}>
      <Head />
      <CMHeader />
      <Container className={root}>
        <Grid
          alignItems="center"
          className={gridContainer}
          container
          direction="column"
          justify="center">
          <Grid item>
            <Grid
              alignItems="flex-start"
              container
              display="flex">
              <Grid
                className={topGridText}
                item
                md={7}
                xs={12}>
                <Box
                  display={isMobile ? "none" : "block"}
                  mb="8px"
                  mr="144px"
                  textAlign="left">
                  <Typography
                    component="h3"
                    mb="24px"
                    variant="h3">
                    Your real-time customer experience advisor.
                  </Typography>
                </Box>
                <Box
                  mb={isMobile ? "36px" : "48px"}
                  mr={isMobile ? "0" : "144px"}
                  mt="16px"
                  textAlign={isMobile ? "center" : "left"}>
                  <Typography
                    component="p"
                    variant="body1">
                    Predictively is a suite of AI-powered tools that provides real-time recommendations for website design and branding to help you connect with, convert, and keep your customers.
                  </Typography>
                </Box>
                <Box
                  mb={isMobile ? "8px" : "24px"}
                  mr={isMobile ? "0" : "144px"}
                  mt="16px"
                  textAlign={isMobile ? "center" : "left"}>
                  <Typography
                    component="p"
                    variant="body1">
                    Try out Predictively for free. Enter your website address below to get recommendations to improve your results:
                  </Typography>
                </Box>
                <Box
                  component="form"
                  mr={isMobile ? "0" : "144px"}
                  onSubmit={handleSubmit}>
                  <Grid
                    container
                    justify="center"
                    spacing={0}>
                    <Grid item xs={10}>
                      <Box mr="17px">
                        <TextField
                          color="primary"
                          error={hasValidationError}
                          fullWidth
                          helperText={
                            hasValidationError
                              ? error?.response?.data.msg ?? "Please enter a valid URL"
                              : undefined
                          }
                          label={hasValidationError ? "Error" : "Enter Your URL"}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder="Enter your website URL"
                          value={url}
                          variant="outlined"/>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        className={startButton}
                        color="secondary"
                        disabled={isLoading || hasValidationError || url === ""}
                        disableElevation={true}
                        fullWidth={isMobile}
                        type="submit"
                        variant="contained">
                        <ArrowForwardIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {isLoading && (
                    <Box py={3}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid
                className={topGridImage}
                item
                md={5}
                xs={12}>
                <Box
                  display={isMobile ? "block" : "none"}
                  mb="16px"
                  textAlign="center">
                  <Typography
                    component="h1"
                    variant="h4">
                    Your real-time user experience advisor.
                  </Typography>
                </Box>
                <Box
                  mx="auto">
                  <CardMedia
                    component="img"
                    image={heroImage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <CMStaggeredLayout content={staggeredGridContent} />
    </ThemeProvider>
  );
};

export default Home;
